import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { PhoneNumber } from "../Pages/Trucks/TruckTypes";
import { COUNTRIES } from '../Utils/countries';


interface Props {
  driverId: number;
  phoneNumber?: PhoneNumber;
  onClose: () => void;
  onSave: (phoneNumber: PhoneNumber) => void;
}

const phoneTypeOptions = [
  { id: 'Pagrindinis', name: 'Pagrindinis' },
  { id: 'Darbo', name: 'Darbo' },
  { id: 'Namu', name: 'Namų' },
  { id: 'Kita', name: 'Kita' },
];

export default function PhoneNumberPopup({ driverId, phoneNumber, onClose, onSave }: Props) {
  const [phonePrefix, setPhonePrefix] = useState('+370');
  const [rawNumber, setRawNumber] = useState('');
  const [type, setType] = useState(phoneNumber?.type || 'Pagrindinis');
  const [comment, setComment] = useState(phoneNumber?.comment || '');

  useEffect(() => {
    if (phoneNumber && phoneNumber.number) {
      const prefix = COUNTRIES.find(p => phoneNumber.number.startsWith(p.code));
      if (prefix) {
        setPhonePrefix(prefix.code);
        setRawNumber(phoneNumber.number.slice(prefix.code.length));
      } else {
        setRawNumber(phoneNumber.number);
      }
    }
  }, [phoneNumber]);

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    setRawNumber(value);
  };

  const handleSave = () => {
    const fullNumber = `${phonePrefix}${rawNumber}`;
    const selectedCountry = COUNTRIES.find(p => p.code === phonePrefix);

    const phoneData: PhoneNumber = {
      id: phoneNumber?.id || Date.now(),
      number: fullNumber,
      type,
      comment,
      country: selectedCountry?.name || ''
    };

    onSave(phoneData);
  };

  return (
    <div className="px-4 pb-2">
      <Row>
        <Col sm={12}>
          <Form.Group className="mb-3">
            <Form.Label>Tel. numeris</Form.Label>
            <Row>
              <Col xs={4} style={{ paddingRight: '5px' }}>
                <Form.Control
                  as="select"
                  value={phonePrefix}
                  onChange={(e) => setPhonePrefix(e.target.value)}
                >
                  {COUNTRIES.map((prefix) => (
                    <option key={prefix.code} value={prefix.code}>
                      {prefix.code} ({prefix.country})
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col xs={8} style={{ paddingLeft: '5px' }}>
                <Form.Control
                  type="text"
                  value={rawNumber}
                  onChange={handleNumberChange}
                  placeholder="12345678"
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>

        <Col sm={12}>
          <Form.Group>
            <Form.Label>Tipas</Form.Label>
            <Form.Control
              as="select"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              {phoneTypeOptions.map((option) => (
                <option key={`type-${option.id}`} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col sm={12}>
          <Form.Group>
            <Form.Label>Komentaras</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>

      <div className="mt-4 flex justify-end gap-2">
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!rawNumber || !type}
        >
          {phoneNumber ? 'Atnaujinti' : 'Pridėti'}
        </Button>
        <Button
          variant="secondary"
          onClick={onClose}
        >
          Atšaukti
        </Button>
      </div>
    </div>
  );
} 